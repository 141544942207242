<template>
  <!-- 添加编辑 -->
  <el-dialog
    :title="Number(form.id) ? '编辑产品' : '添加产品'"
    width="30%"
    v-model="dialogShow"
  >
    <el-form :model="form" :rules="levelRules" ref="levelRef">
      <el-row>
        <el-col :span="18">
          <el-form-item label="产品名称" prop="product_name">
            <el-input v-model="form.product_name" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="缩略图" prop="img">
            <Upload-img v-model="form.img"></Upload-img>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="btnCancel"> 取消 </el-button>
        <el-button type="primary" @click="btnOk"> 确认 </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue'
import { useVModel } from '@vueuse/core'
import { addgiftProductAPI, ProductSaveAPI } from '@/api/product'
import { ElMessage } from 'element-plus'
import UploadImg from '@/components/UploadpImg'
const props = defineProps({
  // 接收 v-model
  modelValue: {
    type: Boolean,
    required: true
  },
  onListData: {
    type: Function
  },

  productGift: {
    type: Object
  }
})
defineEmits(['update:modelValue']) // 注册 v-model 事件
const dialogShow = useVModel(props) // 得到一个响应式的数据，可以直接修改
watch(
  () => props.productGift,
  (val) => {
    form.value = val
  },
  {
    deep: true
  }
)

const form = ref({
  product_name: ''
})

const levelRules = ref({
  product_name: [
    {
      required: true,
      trigger: 'blur',
      message: '产品名称必填'
    }
  ],
  img: [
    {
      required: true,
      trigger: 'blur',
      message: '产品图片必填'
    }
  ]
})
// 获得 Ref 实例
const levelRef = ref(null)
// 取消
const btnCancel = () => {
  dialogShow.value = false
}

// 确认
const btnOk = () => {
  levelRef.value.validate(async (valid) => {
    if (!valid) return
    try {
      // 触发添加 api
      console.log(Number(form.value.id))
      if (Number(form.value.id)) {
        await ProductSaveAPI(form.value)
        ElMessage.success('产品编辑成功。')
      } else {
        await addgiftProductAPI(form.value)
        ElMessage.success('产品添加成功。')
      }
      props.onListData()
    } catch (error) {
      console.log(error)
    } finally {
      dialogShow.value = false
    }
  })
}
</script>

<style></style>
