import request from '@/utils/request'

// 员工管理
// 员工列表
export const productmainListAPI = (params) => {
  return request({
    url: '/productmainList',
    method: 'GET',
    params
  })
}

// 新增主品
export const addProductmainAPI = (data) => {
  return request({
    url: '/addProductmain',
    method: 'POST',
    data
  })
}

// 赠品列表
export const productgiftListAPI = (params) => {
  return request({
    url: '/productgiftList',
    method: 'GET',
    params
  })
}

// 新增赠品
export const addgiftProductAPI = (data) => {
  return request({
    url: '/addgiftProduct',
    method: 'POST',
    data
  })
}

// 产品详情
export const ProductInfoAPI = (params) => {
  return request({
    url: '/ProductInfo',
    method: 'GET',
    params
  })
}

// 编辑产品
export const ProductSaveAPI = (data) => {
  return request({
    url: '/ProductSave',
    method: 'POST',
    data
  })
}

// 下架产品
export const ProductDelAPI = (params) => {
  return request({
    url: '/ProductDel',
    method: 'GET',
    params
  })
}
