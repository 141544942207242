<template>
  <div>
    <el-upload
      class="avatar-uploader"
      :action="UPLOAD_PICTURE_URL"
      :show-file-list="false"
      :headers="headers"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
    >
      <img
        v-if="imageUrl"
        :src="'http://zct.yslts.com/' + imageUrl"
        class="avatar"
      />
      <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>

      <template #tip>
        <div class="text-red">
          建议尺寸：30K以内 200*200 像素的jpg，jpeg，png
         <!-- 照片推荐: 2MB 以下的 PNG , JPEG , JPG 格式的图片 -->
        </div>
      </template>
    </el-upload>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits } from 'vue'
import { useVModel } from '@vueuse/core'
import { UPLOAD_PICTURE } from '@/api/rotation'
import { ElMessage } from 'element-plus'
import { userStore } from '@/store'
const props = defineProps({
  modelValue: {
    type: String,
    default: '',
    required: true
  },

  width: {
    type: String,
    default: '200px'
  },

  height: {
    type: String,
    default: '200px'
  }
})
defineEmits(['update:modelValue']) // 注册 v-model 事件
const imageUrl = useVModel(props) // 得到一个响应式的数据，可以直接修改

// action 路径
const UPLOAD_PICTURE_URL = ref(UPLOAD_PICTURE)

// token 追加
const store = userStore()
const headers = computed(() => {
  return { Authorization: `Bearer ${store.token}` }
})

// 上传之后
const handleAvatarSuccess = (response, uploadFile) => {
  imageUrl.value = response.data.path
}
// 上传之前
const beforeAvatarUpload = (rawFile) => {
  const types = ['image/jpeg', 'image/png', 'image/jpg']
  if (!types.includes(rawFile.type)) {
    ElMessage.error('上传的图片只能是 PNG , JPEG , JPG 的格式')
    return false
  } else if (rawFile.size / 1024 / 1024 > 2) {
    ElMessage.error('图片最大不可以超过 2MB!')
    return false
  }
  return true
}
</script>
<style scoped>
.avatar-uploader .avatar {
  width: v-bind(width);
  height: v-bind(height);
  display: block;
}
</style>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: v-bind(width);
  height: v-bind(height);
  text-align: center;
}
</style>
